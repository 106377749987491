<template>
  <div
    v-if="fileItemData && fileItemData.attachExt"
    :class="['file_view', { file_ellip: isEllip }]"
  >
    <button
      type="button"
      class="btn_delete file_sm"
      @click="$emit('removeFile', index, fileItemData)"
    >
      <span :class="['ico_account ico_filename', getClassByFileType]">{{
        fileItemData.attachType
      }}</span>
      <div class="group_filename">
        <span class="txt_type">{{ isEllip ? fileName2 : "" }}.{{ fileItemData.attachExt }}</span>
        <span class="txt_file">
          <span class="inner_txt_file">{{ fileName }}</span>
        </span>
        <span ref="fileNameEl" class="txt_full_file">{{ fileFullName }}</span>
      </div>
      <span class="ico_account ico_cross">삭제</span>
    </button>
    <div class="file_comments">
      <input
        v-if="isComments"
        v-model="fileItemData.comments"
        type="text"
        class="inp_comm"
        maxLength="40"
        placeholder="첨부파일 상세설명을 입력하세요 (최대 40자)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "StatementFileWriteItem",
  props: {
    index: Number,
    fileItemData: Object,
    isComments: {
      type: Boolean,
      default: true,
    },
    comments: String,
  },
  data() {
    return {
      isEllip: false,
      ellipBackTextLength: 11,
    };
  },
  computed: {
    getClassByFileType() {
      const fileExt = this.fileItemData.attachExt;

      if (!fileExt) return "ico_etc";

      switch (fileExt.toLowerCase()) {
        case "pdf":
          return "ico_pdf";
        case "png":
          return "ico_png";
        case "jpg":
          return "ico_jpg";
        case "jpeg":
          return "ico_jpg";
        case "zip":
          return "ico_zip";
        default:
          return "ico_etc";
      }
    },
    fileFullName() {
      const attachExt = "." + this.fileItemData.attachExt;
      return String(this.fileItemData.orgFile).replace(attachExt, "");
    },
    fileName() {
      if (this.isEllip) {
        return this.fileFullName.slice(0, this.ellipBackTextLength * -1);
      } else {
        return this.fileFullName;
      }
    },
    fileName2() {
      return this.fileFullName.slice(this.ellipBackTextLength * -1);
    },
  },
  watch: {
    fileItemData(newVal, oldVal) {
      if (!newVal || newVal.length === 0) return;

      this.measureEllip();
    },
  },
  mounted() {
    if (this.fileItemData && this.fileItemData.attachExt) {
      this.measureEllip();
    }
  },
  methods: {
    measureEllip() {
      this.$nextTick(function () {
        const $MaxWidth = 220;
        const $textWidths = Boolean($MaxWidth < this.$refs.fileNameEl.offsetWidth);
        this.isEllip = $textWidths;
      });
    },
  },
};
</script>

<style scoped>
.file_view {
  display: block;
  overflow: hidden;
  height: 32px;
  margin-top: 8px;
}

.file_view .btn_delete {
  display: inline-block;
  position: relative;
  float: left;
  width: 320px;
  padding: 7px 38px 7px 10px;
  border-radius: 2px;
  background-color: #f6f6fc;
  outline: none;
  text-align: left;
  vertical-align: top;
}
.file_view .btn_delete + .btn_delete {
  margin-top: 4px;
}

.file_view .ico_filename {
  float: left;
  margin: 2px 8px 2px 0;
}

.file_view .group_filename {
  display: inline-block;
  max-width: calc(100% - 30px);
  vertical-align: top;
}
.file_view .group_filename:after {
  display: block;
  visibility: hidden;
  height: 0;
  font-size: 0;
  clear: both;
  content: "";
}
.file_view .txt_type {
  float: right;
}
.file_view .txt_file {
  display: block;
  overflow: hidden;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.file_view .txt_full_file {
  position: absolute;
  top: -34px;
}

.file_view .ico_cross {
  position: absolute;
  top: 7px;
  right: 12px;
}

.file_comments {
  display: block;
  overflow: hidden;
  padding-left: 8px;
}
.file_view .inp_comm {
  display: inline-block;
  vertical-align: top;
}

/* .file_ellip .txt_type{margin-left:-1px} */
</style>
